//메인화면에 쓰일 scss
$point: #ff9300;
$main: #372874;
$sub1: #b1b5e6;
$sub2: #ffce1f;
$white-1: #fff;
$white-2: #f9f9f9;
$dark-1: #444;
$dark-2: #000;
$red: #f45752;
$boxShadow: rgb(237 237 237) 2px 3px 8px;


$SquareLight: 'NanumSquareLight';
$SquareNomal: 'NanumSquare';
$SquareSemiBold: 'NanumSquareBold';
$SquareBold: 'NanumSquareExtraBold';

//추가 색상들_섬네일 디자인 중심

$extra1: #4046b7;
$extra2: #517fe6;
$extra3: #df716c;
$extra4: #8bdb8a;
$extra5: #efca7f;
$extra6: #faccb9;
$extra7: #e3def1;
$extra8: #ccbeec;
$extra9: #bca8ec;
$extra10: #f3f2f7;
$extra11: #ffc34e;


//about us

.H1 {
  word-break: keep-all;
  font-size: 60px;

  @media screen and (max-width: 768px) {
    font-size: 35px;
  }
}

.H2 {
  word-break: keep-all;
  font-size: 45px;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
}

.H3 {
  word-break: keep-all;
  font-size: 40px;

  @media screen and (max-width: 768px) {
    font-size: 21px;
  }
}

.H4 {
  word-break: keep-all;
  font-size: 35px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.lineHeight-70 {
  line-height: 70px;

  @media screen and (max-width: 768px) {
    line-height: 40px;
  }
}

.lineHeight-72 {
  line-height: 72px;

  @media screen and (max-width: 768px) {
    line-height: 43px;
  }
}


.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.highLight {
  background-color: $sub2;
}

.mb-3 {
  margin-bottom: 30px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-5 {
  margin-top: 50px;
}

.p-3 {
  padding: 30px;
}

.squareBold {
  font-family: $SquareBold;
}

.squareSemi {
  font-family: $SquareSemiBold;
}

.mainColor {
  color: $main;
}

.StarBtns {
  background-color: #F3F6F9;
  border-radius: 16px;
  width: 300px;
  color: #444;
  padding: 10px 0;
  font-size: 40px;
  transition: all 300ms ease;


  &:hover {
    background-color: #ffce1f;
    color: #444;
  }

  @media screen and (max-width: 768px) {
    width: 150px;
    font-size: 23px;
  }

}


//login, signup

.labelFlex {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.signInInput {
  background-color: $white-1;
  border: 1px solid $dark-1;
  padding: 8px 10px;
  border-radius: 5px;
  height: 40px;
  font-size: 15px;
}

.RegCorrect {
  color: #ff9300;
  font-size: 0.9rem;
}

.RegIncorrect {
  color: red;
  font-size: 0.9rem;
}


.orLink {
  padding: 0 12px;
  color: #aaa;
}


.googleButton,
.facebookButton,
.kakaoButton,
.EmailButton {
  box-shadow: $boxShadow;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
  padding: 10px 15px;

  cursor: pointer;
  border: 0;
  outline: 0;
  transition: all 300ms ease;
  font-size: 15px;
  border-radius: 10px;
  margin-bottom: 16px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px 0 inset;
  }

  > p {
    font-size: 13px;
    color: gray;
  }

}

.kakaoButton {
  background-color: #FEE500;
  color: rgb(0, 0, 0, 0.85);

  > img {
    width: 24px;
    height: 24px;
  }
}

.googleButton {
  background-color: $white-1;
  color: $dark-1;

  > svg {
    background-color: transparent;
  }
}

.facebookButton {
  background-color: #3d5a97;
  color: $white-1;

  > svg {
    background-color: $white-1;
  }
}

.EmailButton {
  background-color: $extra7;
  color: $dark-1;
}

.isChecked {
  > p {
    font-size: 13px;

    > a {
      text-decoration: underline;

      &:hover {
        font-weight: 600;
      }
    }
  }
}

//mypage

.CancelBtn {
  background-color: transparent;
  color: #000;
  text-decoration: underline;
  border: 0;
  outline: 0;
  padding: 0;
  font-size: 16px;
  margin-left: 12px;
  cursor: pointer;
  transition: all 300ms ease;

  &:hover {
    font-weight: 600;
  }
}

.Disabled {
  background-color: transparent;
  color: $extra7;
  border: 0;
  margin-left: 12px;
  outline: 0;
  padding: 0;
  font-size: 16px;
}


//membership

.selected {
  border: 3px solid $main;
}

.kakaoPayBtn,
.creditNormal {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $dark-1;
  font-size: 16px;
}

.kakaoPayBtn {
  background-color: #ffeb02;
  padding: 8px 0;
  padding-right: 15px;
  padding-left: 10px;
  border-radius: 10px;
  border: 0;
  outline: 0;

  &:focus {
    border: 1px solid $dark-1;
  }

  > img {
    width: 70px;
  }

  > span {
    font-weight: 600;

    @media screen and (max-width: 680px) {
      display: none;
    }
  }
}

.creditNormal {
  background-color: $white-1;
  box-shadow: rgba(0, 0, 0, 0.15) 0.95px 0.95px 1.6px;
  padding: 8px 20px;
  border-radius: 10px;
  border: 0;
  outline: 0;


  &:focus {
    border: 1px solid $dark-1;
  }

  > svg {
    margin-right: 10px;
    width: 28px;
    height: 28px;
  }

  > span {
    font-weight: 600;
    word-break: keep-all;
  }
}


.creditCard,
.KakaoPayBox {
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.PwElement {
  width: 60px;
}

//이벤트 페이지


.content1_1,
.content1_2 {
  color: $dark-2;

  > h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    word-break: keep-all;

    > span {
      font-weight: 900;
    }

    @media screen and (max-width: 680px) {
      font-size: 1.8rem;
      line-height: 2.3rem;

    }
  }
}

.content1_1 {
  padding-top: 25px;
}

.content1_2 {

  padding-top: 40px;

  > h3 {
    line-height: 3.2rem;
    font-size: 2.7rem;

    @media screen and (max-width: 680px) {
      font-size: 1.6rem;
      line-height: 2.1rem;

    }
  }

}

.content1_3 {
  color: $dark-2;
  word-break: keep-all;

  > div {
    padding: 60px 30px;
    text-align: center;

    > img {
      width: 300px;
    }
  }

  > h4 {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-bottom: 5px;
  }

  > h3 {
    line-height: 2.2rem;
    font-size: 2rem;

    > span {
      font-weight: 900;
    }
  }

  @media screen and (max-width: 680px) {

    > div {
      > img {
        width: 180px;
      }
    }

    > h3 {
      font-size: 1.8rem;
      line-height: 2rem;

    }
  }
}


.content1_4 {
  color: $dark-2;
  background-color: rgba(255, 206, 31, 0.4);
  padding: 10px;

  > h4 {
    font-size: 1.2rem;
    margin-bottom: 5px;
    word-break: keep-all;
  }

  > h3 {
    font-size: 1.8rem;
    line-height: 2rem;

    > span {
      font-weight: 900;
    }
  }


  @media screen and (max-width: 680px) {

    > h3 {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }

}


.box2 {
  background-color: $main;
  padding: 40px;

  > div {
    width: 100%;
    max-width: 600px;
    word-break: keep-all;

    > p {
      color: $white-1;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}

.box3 {
  color: $dark-2;
  padding: 50px 0;


  > div {
    width: 100%;
    max-width: 550px;
    word-break: keep-all;

    > h4 {
      font-size: 2rem;
      line-height: 2.2rem;
      display: inline-block;
      border-bottom: 1px solid $dark-2;
      margin-bottom: 10px;
    }

    > p {
      text-indent: -30px;
      padding-left: 30px;
      font-size: 1.4rem;
      line-height: 1.8rem;

    }

  }

  @media screen and (max-width: 680px) {
    padding-right: 30px;
    padding-left: 30px;

    > div {
      > h4 {
        font-size: 1.8rem;
        line-height: 2rem;
      }

      > p {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }

    }
  }

}


//mypage


.UserFormInputs {

  width: 400px;
  text-align: center;

  > input {

    width: 100%;
    padding: 10px 5px;
    font-size: 1rem;
    border: 1px solid #051619;
    margin-bottom: 10px;


    @media screen and (max-width: 768px) {
      width: 50%;
    }

    @media screen and (max-width: 680px) {
      width: 80%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

}

//멤버십 변경

.memberChangeBtn {
  width: 100%;
  background-color: #f9f9f9;
  border: 1px solid #f9f9f9;
  border-radius: 5px;
  padding: 10px 5px;
  transition: all 300ms ease;

  &:hover {
    box-shadow: rgb(237 237 237) 2px 3px 5px;

  }
}

.memberSelected {
  border: 1px solid #000;
  border-radius: 5px;
  font-weight: 600;
}

//문의

.InquiryLabel {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 18px;
}
