//공통 컴포넌트에 적용될 scss
$point: #ff9300;
$main: #372874;
$sub1: #b1b5e6;
$sub2: #ffce1f;
$white-1: #fff;
$white-2: #f9f9f9;
$dark-1: #444;
$dark-2: #000;
$red: #ff635c;
$boxShadow: rgb(237 237 237) 2px 3px 8px;

//추가 색상들_섬네일 디자인 중심

$extra1: #4046b7;
$extra2: #517fe6;
$extra3: #df716c;
$extra4: #8bdb8a;
$extra5: #efca7f;
$extra6: #faccb9;
$extra7: #e3def1;
$extra8: #ccbeec;
$extra9: #bca8ec;
$extra10: #f3f2f7;
$extra11: #ffc34e;

//폰트 변경

.NeoFont {
  font-family: "Neo둥근모", "neodgm";
}

//checkbox
.checkStyle {
  cursor: pointer;
  > input{
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}

//a link
.linkNodeco {
  text-decoration: none;
}

//detail heading

.HeadingBreak {
  text-align: center;
  margin-bottom: 2em;
  word-break: keep-all;
}

.paddingTop5px {
  padding-top: 5px;
}

//애니메이션


@keyframes updown {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(50%);
  }
}


.animation {

  animation: updown 0.5s infinite alternate;
  -webkit-animation: updown 0.5s infinite alternate;
  -moz-animation: updown 0.5s infinite alternate;
  -o-animation: updown 0.5s infinite alternate;
  -ms-animation: updown 0.5s infinite alternate;

}

//메인화면 헤더

.MenuWeight {

  > a,
  span {
    padding: 0px 10px;
    font-size: 1.1rem;
  }

  > a {

    &:hover {
      font-weight: 600;
    }
  }
}

.selectedToggle {
  font-weight: 600;

  > div {
    font-weight: 400;
  }
}

.MobileMenuWeight {

  > a, p {
    padding: 3px 10px;
    width: 100%;
  }

  > a {
    padding: 3px 20px;
    cursor: pointer;
  &:hover {
    font-weight: 600;
  }
  }
}

//new nav toggle

.newNavBar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  background-color: $extra10;
  list-style: none;
  position: absolute;
  left: 0;

  z-index: 10;
  padding: 20px;
}

.none {
  display: none;
}

.MoblieDropDown {
  border-bottom: 1px solid #444;
  list-style: none;
  cursor: pointer;
  padding: 5px 0 8px;

  > li {
    line-height: 30px;

    &:hover {
      font-weight: 600;
      background-color: rgba(249, 249, 249, 0.6);
    }
  }
}

.logo-header {
  height: 25px;
}

.youtube {
  width: 640px;

  @media screen and (max-width: 768px) {
    width: 100% !important
  }
}

//아이콘 박스
.Icons {

  > svg {
    background-color: #444;
    padding: 2px;
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.circleIcons {

  > div {
    width: 40px;
    height: 40px;
    background-color: #444;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    > svg {
      width: 25px;
      height: 25px;

    }

  }
}

.bgGrayIcons {
  background-color: $white-2;
  padding: 8px 10px;

  > svg {
    cursor: pointer;
  }
}

//mail

.subsBtn {
  background-color: $extra1;
  color: #fff;
  border: 0;
  outline: 0;
  padding: 0;
  padding: 8px 10px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 12px;
  cursor: pointer;
}

.error {
  background-color: #f9f9f9;
  border: 1px solid #444;
  color: #444;
  cursor: pointer;

  outline: 0;
  padding: 8px 10px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 12px;
}

.PrevLetter {

  > a {
    background-color: $sub2;
    border: 1px solid $sub2;
    border-radius: 5px;
    padding: 10px 15px;
    transition: all 300ms ease-in-out;

    &:hover {
      background-color: $point;
      border: 1px solid $point;

      font-weight: 600;
    }
  }
}

.correct {
  color: #ff9300;
}

.incorrect {
  color: red;
}

//_공통


.SidebarBtn {
  background-color: $sub1;
  border: 1px solid #444;
  width: 70px;
  border-left: 0;
  border-top: 0;
  padding: 10px;
  transition: width 300ms ease-in-out;

}

.SideBar {
  width: 100%;
  height: 100%;
  border-right: 1px solid #444;
  padding: 5em 1em;
  transition: width 300ms ease-in-out;

  @media screen and (max-width: 768px) {
    background-color: #fff;
    border-right: 0;
    border-bottom: 1px solid #444;
    padding: 2em 1em;
  }
}

//input

.commonInput {
  padding: 8px 10px;
  width: 100%;
  border: 1px solid #444;
  font-size: 1rem;
}

.inputMb {
  margin-bottom: 5px;
}

.inputMb-10 {
  margin-bottom: 10px;
}

.inputMb-30 {
  margin-bottom: 30px;
}

.inputMt {
  margin-top: 5px;
}

.inputMt-10 {
  margin-top: 10px;
}


//title+input

.TitleInput {
  margin-bottom: 15px;

  > p {
    font-weight: 600;
    font-size: 1.05rem;
    margin-bottom: 8px;
  }
}

.sub_input_text {
  width: 100%;
  padding: 5px;
  font-size: 0.95rem;
  margin-right: 5px;
  outline: none;
  border: 0;
  border-bottom: 1px solid $dark-1;
  background-color: #f9f9f9;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;


  &:focus {
    outline: none;
  }

  @media screen and (max-width: 768px) {
    height: 35px;
  }
}

//label + input

.labelInput {

  > label {
    width: 150px;
    font-weight: 600;
    word-break: keep-all;

    @media screen and (max-width: 480px) {
      margin-bottom: 5px;
    }
  }
}

.labelInputBig {

  > label {
    width: 200px;
    font-weight: 600;
    word-break: keep-all;

    @media screen and (max-width: 480px) {
      margin-bottom: 5px;
    }
  }
}

//select

.commonSelect {

  border: 1px solid $dark-1;
  border-radius: 0;
  color: $dark-1;
  background-color: $white-1;
  cursor: pointer;
  width: 100%;
  padding: 8px 13px;
  font-size: 1rem;
  margin-bottom: 5px;

  /* select 화살표 없애기 */
  appearance: none;
  -webkit-appearance: none;

}

.cotentSelect {
  border: 1px solid $dark-2;
  border-radius: 5px;
  color: $dark-2;
  background-color: $sub2;
  cursor: pointer;
  width: 100%;
  padding: 3px 7px;
  font-size: 1rem;
  margin-bottom: 20px;
  outline: 0;

  /* select 화살표 없애기 */
  appearance: none;
  -webkit-appearance: none;

}

//textarea

.commonTextarea {
  background-color: $white-1;
  border: 1px solid #444;
  max-width: 450px;
  width: 100%;
  height: 300px;
  resize: none;
  font-size: 1rem;
  padding: 10px 15px;
  overflow: auto;
  outline: 0;
  text-align: left;
}

.blogDiv {
  background-color: $white-1;
  border: 1px solid #444;
  width: 100%;
  height: 200px;
  font-size: 1rem;
  padding: 10px 15px;
  overflow: auto;
}

.blogTextarea {
  background-color: $white-1;
  border: 1px solid #444;
  width: 100%;
  height: 200px;
  resize: none;
  font-size: 1rem;
  padding: 10px 15px;
  overflow: auto;
  outline: 0
}


//

.DoneText {
  text-align: center;

  > h2 {
    font-size: 35px;
    font-weight: 900;
    margin-bottom: 15px;
  }

  > p {
    font-size: 18px;
    line-height: 30px;
  }


  @media screen and (max-width: 768px) {
    > h2 {
      font-size: 1.2rem;
    }
  }

}

.DoneButton {
  text-align: center;

  > button:first-child {
    background-color: $main;
    border: 1px solid $main;
    color: $white-1;
    font-size: 20px;
    margin-right: 15px;
    padding: 5px 25px;
    cursor: pointer;

  }

  > button:nth-child(2) {
    background-color: $white-1;
    border: 1px solid $dark-1;
    font-size: 20px;
    padding: 5px 25px;
    cursor: pointer;
  }


  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 20px;

    > button {
      width: 100%
    }

    > button:first-child {
      margin: 0;
    }
  }
}

//modal

.modals {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  animation: modal-bg-show .3s;
  padding: 0 20px;

}

//icons

.close {
  background-color: #f45752;
}

.copy {
  background-color: #ff9300;
}

.download {
  background-color: #ffce1f;
}


//toast

.alertClose {
  cursor: pointer
}

.toast-success {
  background-color: #37a169;
  color: $white-1;
}

.toast-error {
  background-color: #e53e3e;
  color: $white-1;
}

.toast-info {
  background-color: $extra1;
  color: $white-1;
}

.toast-warning {
  background-color: $point;
  color: $white-1;
}

@keyframes modal-bg-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.under480px_w100 {
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.displayImg {

  > img {
    width: 150px;
  }

  @media screen and (max-width: 768px) {
    > img {
      display: none;
    }
  }
}
